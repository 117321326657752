var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-title',{attrs:{"title":"Транспорт | KEL.KZ"}}),_c('Header',{attrs:{"routes":_vm.headerRoutes.defaultsoutes}}),_c('div',{staticClass:"container py-4"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-right pb-3"},[_c('DxDropDownButton',{attrs:{"text":"Добавить","styling-mode":"contained","icon":"add","items":_vm.transport,"display-expr":"name","key-expr":"value","element-attr":{class: 'dx-dropdownbutton-success'}},on:{"item-click":_vm.addNewCar}})],1),_c('div',{staticClass:"col-12"},[_c('DxDataGrid',{ref:_vm.dataGridRefKey,attrs:{"id":"gridContainerCars","data-source":_vm.storeCars,"columns":_vm.columnsCars,"show-borders":true,"row-alternation-enabled":true,"show-row-lines":true,"customize-columns":_vm.customizeColumnsCars,"paging":{pageSize: _vm.pageSize, enabled: true},"pager":_vm.pager,"remote-operations":true,"filter-row":{visible: true}},on:{"row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"masterDetailTemplate",fn:function(ref){
var data = ref.data;
return [(_vm.selectedRowKey == data.key)?_c('Car',{attrs:{"carId":data.key,"agent":_vm.Agent,"getCars":_vm.getCars}}):_vm._e()]}},{key:"rolling-stock-unit-type-cell-template",fn:function(ref){
var data = ref.data;
return [_c('div',[_vm._v(" "+_vm._s(data.data.rollingStockUnitTypeName)+" ")])]}},{key:"specification-cell-template",fn:function(ref){
var data = ref.data;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getSpecificationName(data.data.specification))+" ")])]}},{key:"action-cell-template",fn:function(ref){
var data = ref.data;
return [_c('div',[_c('DxButton',{attrs:{"text":"Изменить","type":"default","styling-mode":"text"},on:{"click":function($event){return _vm.editCar(data.data.carId)}}}),_c('DxButton',{attrs:{"text":"Удалить","type":"danger","styling-mode":"text"},on:{"click":function($event){return _vm.delCar(data.data.carId)}}})],1)]}}])},[_c('DxSelection',{attrs:{"mode":"single"}}),_c('DxHeaderFilter',{attrs:{"visible":true}}),_c('DxMasterDetail',{attrs:{"enabled":true,"template":"masterDetailTemplate"}})],1)],1)])]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"long-title text-center mb-3"},[_c('h1',{staticClass:"page-title"},[_vm._v("Транспорт")])])}]

export { render, staticRenderFns }