<template>
    <div>
        <vue-title title="Транспорт | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes"/>
        <div class="container py-4">
            <div class="long-title text-center mb-3">
                <h1 class="page-title">Транспорт</h1>
            </div>
            <div class="row">
                <div class="col-12 text-right pb-3">
                    <DxDropDownButton
                            text="Добавить"
                            styling-mode="contained"
                            icon="add"
                            @item-click="addNewCar"
                            :items="transport"
                            display-expr="name"
                            key-expr="value"
                            :element-attr="{class: 'dx-dropdownbutton-success'}"
                    />
                </div>
                <div class="col-12">
                    <DxDataGrid
                            id="gridContainerCars"
                            :ref="dataGridRefKey"
                            :data-source="storeCars"
                            :columns="columnsCars"
                            :show-borders="true"
                            :row-alternation-enabled="true"
                            :show-row-lines="true"
                            :customize-columns="customizeColumnsCars"
                            :paging="{pageSize: pageSize, enabled: true}"
                            :pager="pager"
                            :remote-operations="true"
                            :filter-row="{visible: true}"
                            @row-click="onRowClick"
                    >
                        <DxSelection mode="single"/>
                        <DxHeaderFilter
                                :visible="true"
                        />
                        <DxMasterDetail
                                :enabled="true"
                                template="masterDetailTemplate"
                        />
                        <template #masterDetailTemplate="{ data }">
                            <Car
                                    v-if="selectedRowKey == data.key"
                                    :carId="data.key"
                                    :agent="Agent"
                                    :getCars="getCars"
                            />
                        </template>
                        <template #rolling-stock-unit-type-cell-template="{ data }">
                            <div>
                                {{ data.data.rollingStockUnitTypeName }}
                            </div>
                        </template>
                        <template #specification-cell-template="{ data }">
                            <div>
                                {{ getSpecificationName(data.data.specification) }}
                            </div>
                        </template>
                        <template #action-cell-template="{ data }">
                            <div>
                                <DxButton
                                        text="Изменить"
                                        type="default"
                                        styling-mode="text"
                                        @click="editCar(data.data.carId)"
                                />
                                <DxButton
                                        text="Удалить"
                                        type="danger"
                                        styling-mode="text"
                                        @click="delCar(data.data.carId)"
                                />
                            </div>
                        </template>
                    </DxDataGrid>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import headerRoutes from './header-routes';
    import {mapGetters} from 'vuex';
    import {httpClient} from '../../shared/services';
    import {Header, Footer} from '../../shared/components';
    import { DxDataGrid, DxMasterDetail, DxSelection, DxHeaderFilter } from 'devextreme-vue/data-grid';
    import CustomStore from 'devextreme/data/custom_store';
    import DxDropDownButton from 'devextreme-vue/drop-down-button';
    import DxButton from 'devextreme-vue/button';
    import moment from 'moment';

    import Car from './detail/Car';

    const dataGridRefKey = "cars-data-grid";

    export default {
        name: 'CarrierCars',
        components: {
            Header,
            Footer,
            DxDataGrid,
            DxMasterDetail,
            DxSelection,
            DxDropDownButton,
            Car,
            DxHeaderFilter,
            DxButton
        },
        data() {
            return {
                headerRoutes,
                storeCars: null,
                dataGridRefKey,
                selectedRowKey: "",
                transport: [
                    {
                        'name': 'Автомобиль',
                        'value': 'with-motor'
                    },
                    {
                        'name': 'Прицеп',
                        'value': 'no-motor'
                    }
                ],
                pager: {
                    allowedPageSizes: [5, 10, 15, 30],
                    showInfo: true,
                    infoText: 'Страница {0} из {1} (Всего {2})',
                    showNavigationButtons: true,
                    showPageSizeSelector: true,
                    visible: true
                },
                pageSize: 10,
                columnsCars: [
                    {
                        caption: 'Номер',
                        dataField: 'number',
                        allowSorting: true,
                        width: 140,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Бренд',
                        dataField: 'brand',
                        allowSorting: false,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Модель',
                        dataField: 'model',
                        allowSorting: false,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Кузов',
                        dataField: 'rollingStockTypeName',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Грузоподъемность',
                        dataField: 'capacity',
                        allowSorting: true,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                        //cellTemplate: "rolling-stock-unit-type-cell-template",
                    },
                    // {
                    //     caption: 'Номер СР',
                    //     dataField: 'techicalPassport',
                    //     allowSorting: true,
                    //     allowFiltering: false,
                    //     width: 150
                    // },
                    // {
                    //     caption: 'Дата выдачи СР',
                    //     dataField: 'issuedDate',
                    //     dataType: 'datetime',
                    //     format: 'dd.MM.yyyy',
                    //     allowSorting: true,
                    //     width: 140,
                    //     allowResizing: false,
                    //     allowFiltering: false,
                    // },
                    {
                        caption: 'Дата ТО',
                        dataField: 'techInspection',
                        dataType: 'date',
                        format: 'dd.MM.yyyy',
                        allowSorting: true,
                        allowResizing: false,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Дата окончания СП',
                        dataField: 'finisedDateInsurance',
                        dataType: 'date',
                        format: 'dd.MM.yyyy',
                        allowSorting: true,
                        allowResizing: false,
                        allowFiltering: true,
                        allowHeaderFiltering: false
                    },
                    {
                        caption: 'Спецификация',
                        dataField: 'specification',
                        allowSorting: true,
                        allowFiltering: false,
                        allowHeaderFiltering: true,
                        cellTemplate: "specification-cell-template",
                        headerFilter: {
                            dataSource: [{
                                text: 'Тягач',
                                value: 'spcTractor'
                            }, {
                                text: 'Фургон',
                                value: 'spcCar'
                            }, {
                                text: 'Прицеп',
                                value: 'spcTrailer'
                            }]
                        }
                    },
                    {
                        caption: 'Действие',
                        dataField: 'carId',
                        allowSorting: false,
                        allowFiltering: false,
                        allowHeaderFiltering: false,
                        cellTemplate: "action-cell-template",
                        rowType: 'action'
                    }
                ]
            }
        },
        created() {
            window.addEventListener("resize", this.resizeEventHandler);
        },
        destroyed() {
            window.removeEventListener("resize", this.resizeEventHandler);
        },
        mounted() {
            this.getCars();
        },
        methods: {
            getCars() {
                this.storeCars = new CustomStore({
                    key: 'carId',
                    byKey: async function (key) {
                        if(key) {
                            console.log(key);
                        }
                    },
                    load: (loadOptions) => {

                        let tokenId = this.Agent.tokenId;
                        let sortStr = '';
                        let filterStr = '';
                        let takeStr = '';
                        let skeepStr = '';

                        if(loadOptions.take) {
                            takeStr = `&take=${loadOptions.take}`;
                        }

                        if(loadOptions.skip) {
                            skeepStr = `&skeep=${loadOptions.skip}`;
                        }

                        if (loadOptions.sort) {
                            let sort = loadOptions.sort[0];
                            sortStr = `&order_field=${sort.selector}&order_desc=${sort.desc}`;
                        }

                        if (loadOptions.filter) {
                            if(loadOptions.filter.columnIndex != undefined) { // Фильтрация только по одному полю
                                switch (loadOptions.filter.columnIndex) {
                                    case 5:
                                    case 6:
                                        if (Array.isArray(loadOptions.filter[0])) {
                                            filterStr = this.getFilterGroup(loadOptions.filter);
                                        } else {
                                            let dateFilter = moment(loadOptions.filter[2]).format('YYYY-MM-DDTHH:mm:ss');
                                            filterStr = this.getFilterItem(loadOptions.filter[0], loadOptions.filter[1], dateFilter);
                                        }
                                        break;
                                    default:
                                        filterStr = this.getFilterItem(loadOptions.filter[0], loadOptions.filter[1], loadOptions.filter[2]);
                                        break;
                                }

                                filterStr = filterStr ? `&filter={"and":[${filterStr}]}` : '';
                            } else { // Фильтрация по нескольким полям
                                if(loadOptions.filter[0] != 'carId') {
                                    if(loadOptions.filter[0] != '!') {
                                        filterStr = this.getFilterGroup(loadOptions.filter);
                                    } else {
                                        if (Array.isArray(loadOptions.filter[1][0])) {
                                            filterStr = this.getFilterGroup(loadOptions.filter[1], true);
                                        } else {
                                            filterStr = this.getFilterItem(loadOptions.filter[1][0], '<>', loadOptions.filter[1][2]);
                                        }
                                    }

                                    filterStr = filterStr ? `&filter=${filterStr}` : '';
                                }
                            }
                        }

                        return httpClient.get(`v2/Carrier/Cars?tokenId=${tokenId}${takeStr}${skeepStr}${sortStr}${filterStr}`)
                            .then((invoices) => {
                                return {
                                    key: 'carId',
                                    data: invoices.data.items,
                                    totalCount: invoices.data.totals,
                                    summary: invoices.data.totals,
                                    groupCount: invoices.data.count,
                                    userData: invoices.data,
                                };
                            })
                            .catch(() => {
                                throw 'Data Loading Error';
                            });
                    }
                });
            },
            getFilterOp(op) {
                switch (op) {
                    case '>':
                        return 'gt';
                    case '>=':
                        return 'gte';
                    case '<':
                        return 'lt';
                    case '=<':
                    case '<=':
                        return 'lte';
                    case '=':
                        return 'eq';
                    case '<>':
                        return 'neq';
                    case 'contains':
                        return 'con';
                    case 'notcontains':
                        return 'ncon';
                    case 'startswith':
                        return 'sw';
                    case 'endswith':
                        return 'ew';
                }
            },
            getFilterItem(field_name, op, value) {
                return `{"${field_name}":{"op":"${this.getFilterOp(op)}","value":"${value}"}}`;
            },
            getFilterGroup(group_filters, isNo = false) {
                let condition = '';
                let filter_items = '';

                group_filters.forEach(function (filter) {
                    if (Array.isArray(filter)) {
                        if(Array.isArray(filter[0])) {
                            filter_items += this.getFilterGroup(filter) + ',';
                        } else {
                            if(filter[0] == '!') {
                                if(Array.isArray(filter[1][0])) {
                                    filter_items += this.getFilterGroup(filter[1], true) + ',';
                                } else {
                                    filter_items += this.getFilterItem(filter[1][0], '<>', filter[1][2]) + ',';
                                }
                            } else {
                                switch (group_filters.columnIndex) {
                                    case 5:
                                    case 6:
                                        var dateFilter1 = moment(filter[2]).format('YYYY-MM-DDTHH:mm:ss');
                                        filter_items += this.getFilterItem(filter[0], (isNo && filter[1] == '=' ? '<>' : filter[1]), dateFilter1) + ',';
                                        break;
                                    default:
                                        switch (filter.columnIndex) {
                                            case 5:
                                            case 6:
                                                var dateFilter2 = moment(filter[2]).format('YYYY-MM-DDTHH:mm:ss');
                                                filter_items += this.getFilterItem(filter[0], (isNo && filter[1] == '=' ? '<>' : filter[1]), dateFilter2) + ',';
                                                break;
                                            default:
                                                filter_items += this.getFilterItem(filter[0], (isNo && filter[1] == '=' ? '<>' : filter[1]), filter[2]) + ',';
                                                break;
                                        }
                                        break;
                                }
                            }
                        }
                    } else {
                        condition = filter;
                    }
                }, this);

                filter_items = filter_items.replace(/,$/, "");

                return `{"${condition}":[${filter_items}]}`;
            },
            changeColumns(columns) {
                if(columns.length > 0) {
                    columns[0].width = '140px';
                    columns[1].visible = true;
                    columns[2].visible = true;
                    columns[3].visible = true;
                    columns[4].visible = true;
                    columns[5].visible = true;
                    columns[6].visible = true;
                    columns[7].visible = true;
                    columns[8].visible = true;

                    switch (this.$mq) {
                        case 'sm':
                            columns[0].width = '140px';
                            columns[1].visible = false;
                            columns[2].visible = false;
                            columns[3].visible = false;
                            columns[4].visible = false;
                            columns[5].visible = false;
                            columns[6].visible = false;
                            columns[7].visible = false;
                            columns[8].visible = true;
                            break;
                        case 'md':
                        case 'lg':
                            columns[0].width = '140px';
                            columns[1].visible = true;
                            columns[2].visible = false;
                            columns[3].visible = false;
                            columns[4].visible = false;
                            columns[5].visible = false;
                            columns[6].visible = false;
                            columns[7].visible = true;
                            columns[8].visible = true;
                            break;
                    }
                }

                return columns;
            },
            customizeColumnsCars(columns) { //columns
                this.changeColumns(columns);
            },
            onRowClick(e) {
                if(e.rowType == "data" && !e.event.originalEvent.currentTarget.className.includes('dx-button')) {
                    if(this.selectedRowKey != e.key) {
                        if(this.selectedRowKey != "") {
                            this.dataGrid.collapseRow(this.selectedRowKey);
                            this.selectedRowKey = "";
                        }

                        this.selectedRowKey = e.key;

                        this.dataGrid.expandRow(this.selectedRowKey);
                    } else {
                        this.dataGrid.collapseRow(this.selectedRowKey);

                        this.selectedRowKey = "";
                    }
                }
            },
            addNewCar(e) { // e
                this.$router.push({name: 'CarrierCarAdd', params: {motor: e.itemData.value}});
            },
            resizeEventHandler() { // e
                this.dataGrid.option('columns', this.changeColumns(this.dataGrid.option().columns));
                this.dataGrid.repaint();
            },
            getSpecificationName(specification) {
                switch (specification) {
                    case 'spcTractor':
                        return 'Тягач';
                    case 'spcCar':
                        return 'Фургон';
                    case 'spcTrailer':
                        return 'Прицеп';
                }
            },
            delCar(carId) {
                let isConfirm = confirm("Вы действительно хотите удалить этот транспорт?");
                if(isConfirm) {
                    this.$store.dispatch('loading', true).then(() => {
                        return httpClient
                            .delete(`v2/Carrier/Cars/${carId}?tokenId=${this.Agent.tokenId}`)
                            .then(() => {
                                this.getCars();
                            });
                    });
                }
            },
            editCar(carId) {
                this.$router.push({name: 'CarrierCarEdit', params: {carId: carId}});
            }
        },
        computed: {
            ...mapGetters([
                'isLoading',
                'Agent'
            ]),
            dataGrid: function() {
                return this.$refs[dataGridRefKey].instance;
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .tooltip-lable {
        display: block;
        position: absolute;
        padding-top: 5px;
        margin-left: 20px;
    }
</style>
